<template>
  <div>
    <h2 class="text-sm font-bold text-body-color">
      {{ $t("footer.connect") }}
    </h2>
    <div class="grid grid-cols-3 mt-4">
      <div class="col-span-2">
        <div class="flex justify-between">
          <a
            v-for="social in socials"
            :key="social.id"
            :href="social.Url"
            class="flex items-center justify-center w-10 h-10 rounded-full hover:bg-primary-gold hover:bg-opacity-25"
          >
            <img
              :src="getBaseUrl() + social.Image.url"
              :alt="social.Url"
              :title="social.Url"
              class="w-6 h-6"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { baseURL } from "../../api";

export default {
  name: "SectionImprint",
  created() {
    this.fetchSocial();
  },
  methods: {
    getBaseUrl() {
      return baseURL;
    },
    ...mapActions(["fetchSocial"]),
  },
  computed: {
    ...mapGetters({
      lang: "lang",
    }),
    // about: (state) => state.about,
    ...mapState(["socials"]),
  },
  watch: {
    async lang() {
      await this.fetchSocial();
    },
  },
};
</script>

<style lang="scss" scoped></style>
