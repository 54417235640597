<template>
  <div
    v-if="show"
    class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen bg-black bg-opacity-70"
  >
    <div
      @click.self="close_modal()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>
    <div
      class="mx-auto w-full max-w-[750px] min-h-[400px] bg-white"
      v-for="video in video"
      :key="video.id"
    >
      <video controls class="w-full h-full">
        <source :src="getBaseUrl() + video.Video.url" type="video/mp4" />
      </video>
    </div>
    <!-- @click="videoOpen = false" -->
    <button
      @click="close_modal()"
      class="absolute top-0 right-0 flex items-center justify-center w-20 h-20 text-white cursor-pointer hover:bg-primary-gold"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="fill-current w-9 h-9"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { baseURL } from "../../api";

export default {
  props: {
    show: Boolean,
  },

  name: "SectionVideo",
  created() {
    this.fetchVideo();
  },
  methods: {
    close_modal() {
      this.$emit("close");
    },
    getBaseUrl() {
      return baseURL;
    },
    ...mapActions(["fetchVideo"]),
  },
  computed: {
    ...mapState(["video"]),
  },
  //   watch: {
  //     async lang() {
  //       await this.fetchVideo();
  //     },
  //   },
};
</script>

<style lang="scss" scoped></style>
