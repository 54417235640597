<template>
  <div class="px-5 mx-auto sm:px-20 lg:px-5 max-w-7xl">
    <div
      class="grid grid-cols-1 py-10 lg:py-32 lg:grid-cols-2 lg:gap-20"
      v-for="(mediation, index) in mediations"
      :key="index"
    >
      <div :class="index % 2 === 0 ? 'order-2' : 'order-1'">
        <img
          data-aos="fade-left"
          :src="getBaseUrl() + mediation.Image.url"
          :alt="mediation.Title"
          :title="mediation.Title"
          class="object-cover h-full rounded"
        />
      </div>
      <div
        data-aos="fade-right"
        :class="
          index % 2 === 0
            ? 'order-2 mt-8 lg:mt-0 lg:order-1'
            : ' mt-8 lg:mt-0 order-1 lg:order-2'
        "
      >
        <h2
          class="text-2xl font-black sm:text-3xl md:text-4xl text-primary-gold"
        >
          {{ mediation.Title }}
        </h2>
        <p
          class="mt-6 text-[15px] md:text-[17px] leading-[29px] text-body-color"
        >
          <VueShowdown :markdown="`${mediation.Content}`" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { baseURL } from "../api";

export default {
  name: "SectionMediation",
  created() {
    this.fetchMediation();
  },
  methods: {
    getBaseUrl() {
      return baseURL;
    },
    ...mapActions(["fetchMediation"]),
  },
  computed: {
    ...mapGetters({
      lang: "lang",
    }),
    // about: (state) => state.about,
    ...mapState(["mediations"]),
  },
  watch: {
    async lang() {
      await this.fetchMediation();
    },
  },
};
</script>

<style lang="scss" scoped></style>
