import { render, staticRenderFns } from "./SectionAbout.vue?vue&type=template&id=14eaec34&scoped=true&"
import script from "./SectionAbout.vue?vue&type=script&lang=js&"
export * from "./SectionAbout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14eaec34",
  null
  
)

export default component.exports