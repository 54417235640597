import axios from "axios";
import { baseURL } from "../api";

// A single Axios instance for our entire app
const apiClient = axios.create({
  // Base URL for all calls to use
  baseURL: baseURL,
  withCredentials: false,
  // For authentication and configuration
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getVideo() {
    return apiClient.get("videos");
  },
  getAbout(lang) {
    return apiClient.get(`abouts?_locale=${lang}`);
  },
  getMediation(lang) {
    return apiClient.get(`mediations?_locale=${lang}`);
  },
  getContactUs(lang) {
    return apiClient.get(`contact-us?_locale=${lang}`);
  },
  getCarousel(lang) {
    return apiClient.get(`slide-shows?_locale=${lang}`);
  },
  getFooter(lang) {
    return apiClient.get(`footers?_locale=${lang}`);
  },
  getSocial() {
    return apiClient.get("socials");
  },
  getImprint() {
    return apiClient.get("imprints");
  },
  getPrivacy() {
    return apiClient.get("privacies");
  },
};
