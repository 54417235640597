<template>
  <div class="px-5 mx-auto sm:px-20 lg:px-5 max-w-7xl">
    <div
      class="grid grid-cols-1 py-20 lg:py-32 lg:grid-cols-2 lg:gap-20"
      v-for="abouts in abouts"
      :key="abouts.id"
    >
      <div data-aos="fade-left">
        <img
          :src="getBaseUrl() + abouts.Image.url"
          :alt="abouts.Title"
          :title="abouts.Title"
          class="object-cover sm:h-full max-h-[450px] rounded"
        />
      </div>
      <div class="mt-6 lg:mt-0" data-aos="fade-right">
        <h1
          class="text-2xl font-black sm:text-3xl md:text-4xl text-primary-gold"
        >
          {{ abouts.Title }}
        </h1>
        <p
          class="mt-6 text-[15px] md:text-[17px] leading-[29px] text-body-color"
        >
          <VueShowdown :markdown="`${abouts.Content}`" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { baseURL } from "../api";

export default {
  name: "SectionAbout",
  created() {
    this.fetchAbout();
  },
  methods: {
    getBaseUrl() {
      return baseURL;
    },
    ...mapActions(["fetchAbout"]),
  },
  computed: {
    ...mapGetters({
      lang: "lang",
    }),
    // about: (state) => state.about,
    ...mapState(["abouts"]),
  },
  watch: {
    async lang() {
      await this.fetchAbout();
    },
  },
};
</script>

<style scoped></style>
