import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/css/tailwind.css";
import "./assets/css/main.css";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
AOS.init();

// Google anlaytics and GDPR
import VueGtag from "vue-gtag";

const getGDPR = localStorage.getItem("GDPR:accepted");

if (typeof getGDPR !== "undefined" && getGDPR === "true") {
  Vue.use(
    VueGtag,
    {
      config: { id: "G-MHFC50G2HS" },
      params: {
        send_page_view: false,
      },
      appName: "screen views",
      pageTrackerScreenviewEnabled: true,
    },
    router
  );
} else if (typeof getGDPR !== "undefined" && getGDPR === "false") {
  Vue.use(VueGtag, {
    config: { id: "G-MHFC50G2HS" },
    enabled: false,
  });
}

import VueShowdown from "vue-showdown";

// the second parameter of Vue.use() is optional
Vue.use(VueShowdown, {
  // set default flavor of showdown
  flavor: "github",
  // set default options of showdown (will override the flavor options)
  options: {
    emoji: false,
  },
});

import i18n from "./i18n";

Vue.config.productionTip = false;

new Vue({
  created() {
    const getHTMLTag = document.documentElement;
    getHTMLTag.setAttribute("lang", i18n.locale);
  },
  store,
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");
