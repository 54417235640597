<template>
  <div class="max-w-4xl px-5 py-20 mx-auto text-body-color">
    <div v-for="imprint in imprint" :key="imprint.id">
      <VueShowdown :markdown="`${imprint.Content}`" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "SectionImprint",
  created() {
    this.fetchImprint();
  },
  methods: {
    ...mapActions(["fetchImprint"]),
  },
  computed: {
    ...mapGetters({
      lang: "lang",
    }),
    // about: (state) => state.about,
    ...mapState(["imprint"]),
  },
  watch: {
    async lang() {
      await this.fetchImprint();
    },
  },
};
</script>

<style lang="scss" scoped></style>
