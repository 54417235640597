<template>
  <div>
    <SectionHeader />
    <router-view />
  </div>
</template>

<script>
import SectionHeader from "@/components/SectionHeader.vue";

export default {
  name: "App",
  components: {
    SectionHeader,
  },
};
</script>

<style></style>
