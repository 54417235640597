<template>
  <div>
    <vueper-slides
      bullets-outside
      :slide-ratio="1 / 2"
      fixed-height="500px"
      fractions
      progress
      autoplay
      :pause-on-hover="true"
    >
      <vueper-slide
        v-for="(carousel, i) in carousels"
        :key="i"
        :image="getBaseUrl() + carousel.Images.url"
        :alt="carousel.Title"
        :title="carousel.Title"
      >
        <template #loader>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style="
              margin: auto;
              background: 0 0;
              display: block;
              shape-rendering: auto;
            "
            width="120"
            height="120"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.8888888888888888s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(40 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.7777777777777778s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(80 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.6666666666666666s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(120 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.5555555555555556s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(160 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.4444444444444444s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(200 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.3333333333333333s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(240 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.2222222222222222s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(280 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.1111111111111111s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="47"
              y="25.5"
              rx="3"
              ry="4.5"
              width="6"
              height="9"
              fill="#f8b26a"
              transform="rotate(320 50 50)"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
              />
            </rect>
          </svg>
          <!-- <span>Loading...</span> -->
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { baseURL } from "../api";

import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "SectionCarousel",
  components: { VueperSlides, VueperSlide },

  created() {
    this.fetchCarousel();
  },
  methods: {
    getBaseUrl() {
      return baseURL;
    },
    ...mapActions(["fetchCarousel"]),
  },
  computed: {
    ...mapGetters({
      lang: "lang",
      carousels: "sortedCarousels",
    }),
  },
  watch: {
    async lang() {
      await this.fetchCarousel();
    },
  },
};
</script>

<style scoped>
.vueperslides__progress {
  background: #ff5252 !important;
  color: #ff5252 !important;
}

.vueperslide__title {
  font-size: 7em !important;
  opacity: 0.7 !important;
}
</style>
