<template>
  <div>
    <div v-for="footer in footer" :key="footer.id">
      <img
        :src="getBaseUrl() + footer.Logo.url"
        alt="sungoldwater logo"
        title="sungoldwater logo"
        class="h-[82px]"
      />
      <p class="mt-6 text-sm leading-[29px] text-body-color">
        {{ footer.Address }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { baseURL } from "../../api";

export default {
  name: "SectionLogo",
  created() {
    this.fetchFooter();
  },
  methods: {
    getBaseUrl() {
      return baseURL;
    },
    ...mapActions(["fetchFooter"]),
  },
  computed: {
    ...mapGetters({
      lang: "lang",
    }),
    // about: (state) => state.about,
    ...mapState(["footer"]),
  },
  watch: {
    async lang() {
      await this.fetchFooter();
    },
  },
};
</script>

<style lang="scss" scoped></style>
