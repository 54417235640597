<template>
  <div>
    <select
      v-model="$i18n.locale"
      class="hidden text-sm border border-gray-200 rounded lg:block focus:border-primary-gold focus:ring-0"
      @change="handleChange($event)"
    >
      <option value="en">English</option>
      <option value="de">Germany</option>
    </select>
    <select
      v-model="$i18n.locale"
      class="text-sm border border-gray-200 rounded lg:hidden focus:border-primary-gold focus:ring-0"
      @change="handleChange($event)"
    >
      <option value="en">En</option>
      <option value="de">De</option>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "LocaleSwitch",
  computed: {
    ...mapGetters({
      lang: "lang",
    }),
  },
  methods: {
    ...mapActions({
      changeLanguage: "changeLanguage",
    }),
    handleChange(event) {
      this.changeLanguage(event.target.value);
      localStorage.setItem("lang", event.target.value);
      const getHTMLTag = document.documentElement;
      getHTMLTag.setAttribute("lang", this.$i18n.locale);
    },
  },
};
</script>
