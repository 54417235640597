<template>
  <section class="px-5 mx-auto sm:px-20 lg:px-5 max-w-7xl">
    <div class="w-full py-10 lg:py-32">
      <div
        class="relative z-20 h-[300px] overflow-hidden rounded-lg md:h-[450px]"
      >
        <div class="absolute top-0 left-0 w-full h-full">
          <img
            src="../assets/video/image-01.jpg"
            alt="People watching video of sungodlwater"
            title="People watching video of sungodlwater"
            class="object-cover object-center w-full h-full"
          />
        </div>
        <div
          class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-opacity-60 bg-primary-gold"
        >
          <a
            @click="showVideo = true"
            class="absolute z-20 flex h-20 w-20 items-center justify-center rounded-full bg-white text-primary md:h-[100px] md:w-[100px]"
          >
            <span
              class="absolute top-0 right-0 z-[-1] h-full w-full animate-ping rounded-full bg-white bg-opacity-20 delay-300 duration-1000"
            ></span>
            <svg
              width="23"
              height="27"
              viewBox="0 0 23 27"
              class="fill-current"
            >
              <path
                d="M22.5 12.634C23.1667 13.0189 23.1667 13.9811 22.5 14.366L2.25 26.0574C1.58333 26.4423 0.750001 25.9611 0.750001 25.1913L0.750002 1.80866C0.750002 1.03886 1.58334 0.557731 2.25 0.942631L22.5 12.634Z"
              />
            </svg>
          </a>
        </div>

        <div>
          <span class="absolute z-40 top-4 left-4">
            <svg
              width="50"
              height="79"
              viewBox="0 0 50 79"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="47.7119"
                cy="76.9617"
                r="1.74121"
                transform="rotate(180 47.7119 76.9617)"
                fill="white"
              />
              <circle
                cx="47.7119"
                cy="61.6385"
                r="1.74121"
                transform="rotate(180 47.7119 61.6385)"
                fill="white"
              />
              <circle
                cx="47.7119"
                cy="46.3163"
                r="1.74121"
                transform="rotate(180 47.7119 46.3163)"
                fill="white"
              />
              <circle
                cx="47.7119"
                cy="16.7159"
                r="1.74121"
                transform="rotate(180 47.7119 16.7159)"
                fill="white"
              />
              <circle
                cx="47.7119"
                cy="31.3421"
                r="1.74121"
                transform="rotate(180 47.7119 31.3421)"
                fill="white"
              />
              <circle
                cx="47.7119"
                cy="1.74121"
                r="1.74121"
                transform="rotate(180 47.7119 1.74121)"
                fill="white"
              />
              <circle
                cx="32.3916"
                cy="76.9617"
                r="1.74121"
                transform="rotate(180 32.3916 76.9617)"
                fill="white"
              />
              <circle
                cx="32.3877"
                cy="61.6384"
                r="1.74121"
                transform="rotate(180 32.3877 61.6384)"
                fill="white"
              />
              <circle
                cx="32.3916"
                cy="46.3162"
                r="1.74121"
                transform="rotate(180 32.3916 46.3162)"
                fill="white"
              />
              <circle
                cx="32.3916"
                cy="16.7161"
                r="1.74121"
                transform="rotate(180 32.3916 16.7161)"
                fill="white"
              />
              <circle
                cx="32.3877"
                cy="31.342"
                r="1.74121"
                transform="rotate(180 32.3877 31.342)"
                fill="white"
              />
              <circle
                cx="32.3916"
                cy="1.74145"
                r="1.74121"
                transform="rotate(180 32.3916 1.74145)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="76.9617"
                r="1.74121"
                transform="rotate(180 17.0674 76.9617)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="61.6384"
                r="1.74121"
                transform="rotate(180 17.0674 61.6384)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="46.3162"
                r="1.74121"
                transform="rotate(180 17.0674 46.3162)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="16.7161"
                r="1.74121"
                transform="rotate(180 17.0674 16.7161)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="31.342"
                r="1.74121"
                transform="rotate(180 17.0674 31.342)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="1.74145"
                r="1.74121"
                transform="rotate(180 17.0674 1.74145)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="76.9617"
                r="1.74121"
                transform="rotate(180 1.74316 76.9617)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="61.6384"
                r="1.74121"
                transform="rotate(180 1.74316 61.6384)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="46.3162"
                r="1.74121"
                transform="rotate(180 1.74316 46.3162)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="16.7161"
                r="1.74121"
                transform="rotate(180 1.74316 16.7161)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="31.342"
                r="1.74121"
                transform="rotate(180 1.74316 31.342)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="1.74145"
                r="1.74121"
                transform="rotate(180 1.74316 1.74145)"
                fill="white"
              />
            </svg>
          </span>
          <span class="absolute z-40 bottom-4 right-4">
            <svg
              width="50"
              height="79"
              viewBox="0 0 50 79"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="47.7119"
                cy="76.9617"
                r="1.74121"
                transform="rotate(180 47.7119 76.9617)"
                fill="white"
              />
              <circle
                cx="47.7119"
                cy="61.6385"
                r="1.74121"
                transform="rotate(180 47.7119 61.6385)"
                fill="white"
              />
              <circle
                cx="47.7119"
                cy="46.3163"
                r="1.74121"
                transform="rotate(180 47.7119 46.3163)"
                fill="white"
              />
              <circle
                cx="47.7119"
                cy="16.7159"
                r="1.74121"
                transform="rotate(180 47.7119 16.7159)"
                fill="white"
              />
              <circle
                cx="47.7119"
                cy="31.3421"
                r="1.74121"
                transform="rotate(180 47.7119 31.3421)"
                fill="white"
              />
              <circle
                cx="47.7119"
                cy="1.74121"
                r="1.74121"
                transform="rotate(180 47.7119 1.74121)"
                fill="white"
              />
              <circle
                cx="32.3916"
                cy="76.9617"
                r="1.74121"
                transform="rotate(180 32.3916 76.9617)"
                fill="white"
              />
              <circle
                cx="32.3877"
                cy="61.6384"
                r="1.74121"
                transform="rotate(180 32.3877 61.6384)"
                fill="white"
              />
              <circle
                cx="32.3916"
                cy="46.3162"
                r="1.74121"
                transform="rotate(180 32.3916 46.3162)"
                fill="white"
              />
              <circle
                cx="32.3916"
                cy="16.7161"
                r="1.74121"
                transform="rotate(180 32.3916 16.7161)"
                fill="white"
              />
              <circle
                cx="32.3877"
                cy="31.342"
                r="1.74121"
                transform="rotate(180 32.3877 31.342)"
                fill="white"
              />
              <circle
                cx="32.3916"
                cy="1.74145"
                r="1.74121"
                transform="rotate(180 32.3916 1.74145)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="76.9617"
                r="1.74121"
                transform="rotate(180 17.0674 76.9617)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="61.6384"
                r="1.74121"
                transform="rotate(180 17.0674 61.6384)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="46.3162"
                r="1.74121"
                transform="rotate(180 17.0674 46.3162)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="16.7161"
                r="1.74121"
                transform="rotate(180 17.0674 16.7161)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="31.342"
                r="1.74121"
                transform="rotate(180 17.0674 31.342)"
                fill="white"
              />
              <circle
                cx="17.0674"
                cy="1.74145"
                r="1.74121"
                transform="rotate(180 17.0674 1.74145)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="76.9617"
                r="1.74121"
                transform="rotate(180 1.74316 76.9617)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="61.6384"
                r="1.74121"
                transform="rotate(180 1.74316 61.6384)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="46.3162"
                r="1.74121"
                transform="rotate(180 1.74316 46.3162)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="16.7161"
                r="1.74121"
                transform="rotate(180 1.74316 16.7161)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="31.342"
                r="1.74121"
                transform="rotate(180 1.74316 31.342)"
                fill="white"
              />
              <circle
                cx="1.74316"
                cy="1.74145"
                r="1.74121"
                transform="rotate(180 1.74316 1.74145)"
                fill="white"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
    <SectionVideo :show="showVideo" @close="showVideo = false"> </SectionVideo>
  </section>
</template>

<script>
import SectionVideo from "@/components/modal/SectionVideo.vue";
export default {
  components: {
    SectionVideo,
  },
  data() {
    return {
      showVideo: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
