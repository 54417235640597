<template>
  <div
    class="sticky top-0 z-50 border-b border-primary-gold border-opacity-20 backdrop-blur-md"
  >
    <div
      class="flex flex-wrap items-center justify-between px-5 py-1 mx-auto max-w-7xl"
    >
      <div>
        <img
          src="../assets/logo.svg"
          alt="sungoldwater logo"
          title="sungoldwater logo"
          class="lg:h-[92px] h-[70px]"
        />
      </div>
      <div class="hidden lg:block">
        <ul class="space-x-20 font-semibold lg:flex-wrap lg:flex">
          <li v-for="nav in navs" :key="nav.id">
            <a :href="'#' + nav.id" class="px-3 py-2 hover:bg-gray-200">
              {{ $t(`nav.${nav.name}`) }}
            </a>
          </li>
        </ul>
      </div>
      <div>
        <LocalSwitch />
      </div>
      <div class="lg:hidden">
        <svg
          @click="toggleView"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-8 h-8 text-gray-600 hover:text-gray-400"
        >
          <path
            v-if="!isOpen"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
          <path
            v-if="isOpen"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div
      :class="isOpen ? 'block max-w-7xl  px-5 border-t ' : 'hidden'"
      class="lg:hidden"
    >
      <ul
        class="py-3 space-y-4 text-sm font-semibold sm:py-4 sm:space-y-0 sm:flex sm:justify-between sm:items-center"
      >
        <li v-for="nav in navs" :key="nav.id" class="w-full mt-2">
          <a :href="'#' + nav.id" class="px-2 py-2 hover:bg-gray-200">
            {{ $t(`nav.${nav.name}`) }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LocalSwitch from "./LocalSwitch.vue";
export default {
  name: "SectionHeader",
  components: {
    LocalSwitch,
  },
  data() {
    return {
      isOpen: false,
      navs: [
        { name: "home", id: "home" },
        { name: "mediation", id: "mediation" },
        { name: "about", id: "about" },
        { name: "contact", id: "contact" },
      ],
    };
  },
  methods: {
    toggleView() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped></style>
