<template>
  <div class="max-w-4xl px-5 py-20 mx-auto text-body-color">
    <div v-for="privacy in privacy" :key="privacy.id">
      <VueShowdown :markdown="`${privacy.Content}`" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "SectionPrivacy",
  created() {
    this.fetchPrivacy();
  },
  methods: {
    ...mapActions(["fetchPrivacy"]),
  },
  computed: {
    ...mapGetters({
      lang: "lang",
    }),
    ...mapState(["privacy"]),
  },
  watch: {
    async lang() {
      await this.fetchPrivacy();
    },
  },
};
</script>

<style scoped></style>
