import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import HomeView from "../views/HomeView.vue";
import SectionImprint from "../views/SectionImprint.vue";
import SectionPrivacy from "../views/SectionPrivacy.vue";
import LegalLayout from "@/layout/LegalsLayout.vue";
import MainLayout from "@/layout/MainLayout.vue";

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: HomeView,
      },
    ],
  },
  {
    path: "/legal",
    component: LegalLayout,
    children: [
      {
        path: "imprint",
        name: "Imprint",
        component: SectionImprint,
      },
      {
        path: "privacy",
        name: "Privacy",
        component: SectionPrivacy,
      },
    ],
  },
  // {
  //   path: "/imprint",
  //   name: "Imprint",
  //   component: SectionImprint,
  // },
  // {
  //   path: "/privacy",
  //   name: "Privacy",
  //   component: SectionPrivacy,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
