<template>
  <div
    v-if="isOpen"
    class="fixed z-50 max-w-5xl p-4 mx-6 bg-gray-800 rounded place-items-center bottom-10 sm:right-32"
  >
    <div>
      <p class="text-sm text-gray-200">
        {{ $t("GDPR.message") }}
      </p>
      <div class="flex justify-center mt-2 space-x-20">
        <button
          @click="deny"
          class="px-3 py-1 text-sm font-bold text-red-700 rounded hover:bg-red-900/30"
        >
          {{ $t("GDPR.deny") }}
        </button>
        <button
          @click="accept"
          class="px-3 py-1 text-sm font-bold text-green-700 rounded hover:bg-green-900/30"
        >
          {{ $t("GDPR.accept") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    if (!this.getGDPR() === true) {
      this.isOpen = true;
    }
  },
  methods: {
    getGDPR() {
      return localStorage.getItem("GDPR:accepted", true);
    },
    accept() {
      this.isOpen = false;
      localStorage.setItem("GDPR:accepted", true);
    },
    deny() {
      this.isOpen = false;
      localStorage.setItem("GDPR:accepted", false);
    },
  },
};
</script>

<style scoped></style>
