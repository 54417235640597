<template>
  <div class="bg-gray-100">
    <div class="px-5 mx-auto max-w-7xl">
      <div
        class="grid grid-cols-1 gap-20 py-24 place-items-center lg:grid-cols-3"
      >
        <SectionLogo />
        <SectionSocial />
        <div>
          <div class="text-sm">
            <router-link
              to="/legal/imprint"
              target="_blank"
              class="px-2 py-1 hover:bg-gray-200 text-body-color"
            >
              {{ $t("footer.imprint") }}
            </router-link>
            <span class="mx-1 text-body-color">|</span>
            <router-link
              to="/legal/privacy"
              target="_blank"
              class="px-2 py-1 hover:bg-gray-200 text-body-color"
            >
              {{ $t("footer.privacy") }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="py-10 text-center leading-[29px] text-body-color">
        &copy;{{ new Date().getFullYear() }} {{ $t("footer.copy-right") }}
      </div>
    </div>
  </div>
</template>

<script>
import SectionLogo from "@/components/footer/SectionLogo.vue";
import SectionSocial from "@/components/footer/SectionSocial.vue";

export default {
  components: {
    SectionLogo,
    SectionSocial,
  },
};
</script>

<style lang="scss" scoped></style>
