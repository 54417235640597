import Vue from "vue";
import Vuex from "vuex";
import DataService from "../services/DataServices.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    carousels: [],
    abouts: [],
    contact: [],
    video: [],
    mediations: [],
    footer: [],
    socials: [],
    imprint: [],
    privacy: [],
    lang: localStorage.getItem("lang") || "de",
  },
  getters: {
    lang(state) {
      return state.lang;
    },
    sortedCarousels(state) {
      return state.carousels.sort((a, b) => a.sequence - b.sequence);
    },
  },
  mutations: {
    SET_LANG(state, lang) {
      state.lang = lang;
    },
    SET_CAROUSEL(state, carousel) {
      state.carousels = carousel;
    },
    SET_ABOUT(state, about) {
      state.abouts = about;
    },
    SET_MEDIATION(state, mediation) {
      state.mediations = mediation;
    },
    SET_VIDEO(state, video) {
      state.video = video;
    },
    SET_FOOTER(state, footer) {
      state.footer = footer;
    },
    SET_SOCIAL(state, social) {
      state.socials = social;
    },
    SET_IMPRINT(state, imprint) {
      state.imprint = imprint;
    },
    SET_PRIVACY(state, privacy) {
      state.privacy = privacy;
    },
    SET_CONTACT_US(state, contact) {
      state.contact = contact;
    },
  },
  actions: {
    changeLanguage({ commit }, lang) {
      commit("SET_LANG", lang);
    },
    fetchCarousel({ commit, state }) {
      DataService.getCarousel(state.lang)
        .then((response) => {
          commit("SET_CAROUSEL", response.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching carousel:" + error.message);
        });
    },
    fetchContact({ commit, state }) {
      DataService.getContactUs(state.lang)
        .then((response) => {
          commit("SET_CONTACT_US", response.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching contacts:" + error.message);
        });
    },
    fetchAbout({ commit, state }) {
      DataService.getAbout(state.lang)
        .then((response) => {
          commit("SET_ABOUT", response.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching about:" + error.message);
        });
    },
    fetchMediation({ commit, state }) {
      DataService.getMediation(state.lang)
        .then((response) => {
          commit("SET_MEDIATION", response.data);
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching mediation:" + error.message
          );
        });
    },
    fetchVideo({ commit }) {
      DataService.getVideo()
        .then((response) => {
          commit("SET_VIDEO", response.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching video:" + error.message);
        });
    },
    fetchFooter({ commit, state }) {
      DataService.getFooter(state.lang)
        .then((response) => {
          commit("SET_FOOTER", response.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching footer:" + error.message);
        });
    },
    fetchSocial({ commit }) {
      DataService.getSocial()
        .then((response) => {
          commit("SET_SOCIAL", response.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching social:" + error.message);
        });
    },
    fetchImprint({ commit }) {
      DataService.getImprint()
        .then((response) => {
          commit("SET_IMPRINT", response.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching imprint:" + error.message);
        });
    },
    fetchPrivacy({ commit }) {
      DataService.getPrivacy()
        .then((response) => {
          commit("SET_PRIVACY", response.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching privacy:" + error.message);
        });
    },
    postMessage({ commit }) {
      DataService.getPrivacy()
        .then((response) => {
          commit("SET_PRIVACY", response.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching privacy:" + error.message);
        });
    },
  },
  modules: {},
});
