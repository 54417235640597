<template>
  <div>
    <SectionCarousel id="home" class="scroll-mt-[250px] lg:scroll-mt-32" />
    <SectionAbout
      id="about"
      class="scroll-mt-[180px] md:scroll-mt-[90px] lg:scroll-mt-[100px]"
    />
    <SectionVideo />
    <SectionMediation
      id="mediation"
      class="scroll-mt-[210px] md:scroll-mt-[130px] lg:scroll-mt-[100px]"
    />
    <SectionContact
      id="contact"
      class="scroll-mt-[180px] md:scroll-mt-[110px] lg:scroll-mt-[100px]"
    />
  </div>
</template>

<script>
import SectionCarousel from "@/components/SectionCarousel.vue";
import SectionAbout from "@/components/SectionAbout.vue";
import SectionVideo from "@/components/SectionVideo.vue";
import SectionMediation from "@/components/SectionMediation.vue";
import SectionContact from "@/components/SectionContact.vue";
export default {
  components: {
    SectionCarousel,
    SectionAbout,
    SectionVideo,
    SectionMediation,
    SectionContact,
  },
};
</script>

<style lang="scss" scoped></style>
