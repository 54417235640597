<template>
  <section class="relative z-10 px-5 mx-auto max-w-7xl">
    <div class="container py-20 lg:py-32">
      <div class="grid gap-4 lg:grid-cols-2">
        <div class="">
          <div class="mb-12">
            <span class="block mb-4 text-base font-semibold text-body-color">
              Contact Us
            </span>
            <h2 class="mb-6 text-2xl font-black sm:text-3xl text-primary-gold">
              {{ $t("contact.get-in-touch") }}
            </h2>
            <div class="text-muted">
              <h2 class="text-lg font-bold text-body-color">
                {{ contact.title }}
              </h2>
              <p class="leading-[29px] text-body-color mb-9">
                <VueShowdown :markdown="`${contact.content}`" />
              </p>
            </div>
            <div
              class="mb-8 flex w-full max-w-[370px]"
              v-for="contact in contact.contact"
              :key="contact.id"
            >
              <div
                class="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary-gold bg-opacity-5 text-primary-gold sm:h-[70px] sm:max-w-[70px]"
              >
                <img
                  :src="getBaseUrl() + contact.icon.url"
                  :alt="contact.title"
                  :title="contact.title"
                  class="w-6 h-6"
                />
              </div>
              <div class="w-full">
                <h3 class="mb-1 font-bold text-gray-700 md:text-xl">
                  {{ contact.title }}
                </h3>
                <p class="text-base leading-[29px] text-body-color">
                  <VueShowdown :markdown="`${contact.content}`" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full sm:px-0 lg:px-10">
          <div class="relative p-4 bg-white rounded-lg shadow-lg sm:p-8 lg:p-6">
            <form ref="form" @submit.prevent="sendEmail">
              <div class="mb-4">
                <p
                  v-if="success"
                  class="px-4 py-2 text-green-700 rounded bg-green-700/20"
                >
                  {{ success }}
                </p>
                <p
                  v-if="error"
                  class="px-4 py-2 text-red-700 rounded bg-red-700/20"
                >
                  {{ error }}
                </p>
              </div>
              <div class="mb-6">
                <input
                  v-model="name"
                  type="text"
                  required
                  name="name"
                  :placeholder="$t('form.name')"
                  class="border-gray-300 w-full rounded border py-3 px-[14px] text-sm md:text-base outline-none focus:border-primary-gold focus:ring-1 focus:ring-primary-gold focus-visible:shadow-none"
                />
              </div>
              <div class="mb-6">
                <input
                  v-model="email"
                  type="email"
                  required
                  name="email"
                  :placeholder="$t('form.email')"
                  class="border-gray-300 w-full rounded border py-3 px-[14px] text-sm md:text-base text-body-color outline-none focus:border-primary-gold focus:ring-1 focus:ring-primary-gold focus-visible:shadow-none"
                />
              </div>
              <div class="mb-6">
                <input
                  required
                  v-model="subject"
                  name="subject"
                  type="text"
                  :placeholder="$t('form.subject')"
                  class="border-gray-300 w-full rounded border py-3 px-[14px] text-sm md:text-base text-body-color outline-none focus:border-primary-gold focus:ring-1 focus:ring-primary-gold focus-visible:shadow-none"
                />
              </div>
              <div class="mb-6">
                <textarea
                  v-model="message"
                  rows="6"
                  name="message"
                  required
                  :placeholder="$t('form.message')"
                  class="border-gray-300 w-full resize-none rounded border py-3 px-[14px] text-sm md:text-base outline-none focus:border-primary-gold focus:ring-1 focus:ring-primary-gold focus-visible:shadow-none"
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  class="w-full p-3 text-white transition border rounded border-primary-gold border-primary bg-primary-gold bg-primary hover:bg-opacity-90"
                >
                  {{ $t("form.send-message") }}
                </button>
              </div>
            </form>
            <div class="hidden md:block">
              <span class="absolute -top-10 -right-4 md:-right-9 z-[-1]">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                    fill="#cc9942"
                  />
                </svg>
              </span>
              <span class="absolute -right-5 md:-right-10 top-[90px] z-[-1]">
                <svg
                  width="34"
                  height="134"
                  viewBox="0 0 34 134"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="31.9993"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 31.9993 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 31.9993 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 31.9993 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 31.9993 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 31.9993 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 31.9993 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 31.9993 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 31.9993 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 31.9993 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 31.9993 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 17.3333 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 17.3333 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 17.3333 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 17.3333 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 17.3333 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 17.3333 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 17.3333 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 17.3333 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 17.3333 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 17.3333 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 2.66536 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 2.66536 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 2.66536 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 2.66536 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 2.66536 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 2.66536 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 2.66536 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 2.66536 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 2.66536 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 2.66536 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                </svg>
              </span>
              <span class="absolute -left-7 -bottom-7 z-[-1]">
                <svg
                  width="107"
                  height="134"
                  viewBox="0 0 107 134"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="104.999"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 104.999 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="104.999"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 104.999 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="104.999"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 104.999 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="104.999"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 104.999 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="104.999"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 104.999 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="104.999"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 104.999 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="104.999"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 104.999 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="104.999"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 104.999 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="104.999"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 104.999 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="104.999"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 104.999 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="90.3333"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 90.3333 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="90.3333"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 90.3333 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="90.3333"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 90.3333 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="90.3333"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 90.3333 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="90.3333"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 90.3333 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="90.3333"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 90.3333 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="90.3333"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 90.3333 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="90.3333"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 90.3333 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="90.3333"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 90.3333 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="90.3333"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 90.3333 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="75.6654"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 75.6654 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 31.9993 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="75.6654"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 75.6654 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 31.9993 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="75.6654"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 75.6654 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 31.9993 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="75.6654"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 75.6654 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 31.9993 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="75.6654"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 75.6654 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 31.9993 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="75.6654"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 75.6654 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 31.9993 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="75.6654"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 75.6654 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 31.9993 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="75.6654"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 75.6654 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 31.9993 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="75.6654"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 75.6654 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 31.9993 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="75.6654"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 75.6654 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="31.9993"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 31.9993 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="60.9993"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 60.9993 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 17.3333 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="60.9993"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 60.9993 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 17.3333 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="60.9993"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 60.9993 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 17.3333 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="60.9993"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 60.9993 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 17.3333 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="60.9993"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 60.9993 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 17.3333 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="60.9993"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 60.9993 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 17.3333 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="60.9993"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 60.9993 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 17.3333 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="60.9993"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 60.9993 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 17.3333 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="60.9993"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 60.9993 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 17.3333 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="60.9993"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 60.9993 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="17.3333"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 17.3333 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="46.3333"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 46.3333 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="132"
                    r="1.66667"
                    transform="rotate(180 2.66536 132)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="46.3333"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 46.3333 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="117.333"
                    r="1.66667"
                    transform="rotate(180 2.66536 117.333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="46.3333"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 46.3333 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="102.667"
                    r="1.66667"
                    transform="rotate(180 2.66536 102.667)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="46.3333"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 46.3333 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="88"
                    r="1.66667"
                    transform="rotate(180 2.66536 88)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="46.3333"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 46.3333 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="73.3333"
                    r="1.66667"
                    transform="rotate(180 2.66536 73.3333)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="46.3333"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 46.3333 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="45"
                    r="1.66667"
                    transform="rotate(180 2.66536 45)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="46.3333"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 46.3333 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="16"
                    r="1.66667"
                    transform="rotate(180 2.66536 16)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="46.3333"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 46.3333 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="59"
                    r="1.66667"
                    transform="rotate(180 2.66536 59)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="46.3333"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 46.3333 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="30.6666"
                    r="1.66667"
                    transform="rotate(180 2.66536 30.6666)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="46.3333"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 46.3333 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                  <circle
                    cx="2.66536"
                    cy="1.66665"
                    r="1.66667"
                    transform="rotate(180 2.66536 1.66665)"
                    fill="#cc9942"
                    opacity="0.5"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from "@emailjs/browser";
import { mapState, mapGetters, mapActions } from "vuex";
import { baseURL } from "../api";

export default {
  name: "SectionContact",
  data() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
      success: "",
      error: "",
    };
  },
  created() {
    this.fetchContact();
  },
  methods: {
    sendEmail(e) {
      console.log(e.target);
      emailjs
        .sendForm(
          "service_zlnw8io",
          "template_b0f7soj",
          e.target,
          "user_pHlXRSBk1ewiAuGzuc7jE"
        )
        .then(
          (result) => {
            this.success = "Message sent successfully";
            console.log("SUCCESS", result.text);
          },
          (error) => {
            this.error = "Faied to send message";
            console.log("FAILED", error.text);
          }
        );
    },
    getBaseUrl() {
      return baseURL;
    },
    ...mapActions(["fetchContact"]),
  },
  computed: {
    ...mapGetters({
      lang: "lang",
    }),
    // about: (state) => state.about,
    ...mapState(["contact"]),
  },
  watch: {
    async lang() {
      await this.fetchContact();
    },
  },
};
</script>

<style lang="scss" scoped></style>
