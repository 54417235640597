<template>
  <div>
    <div class="sticky top-0 z-50 border-b backdrop-blur-md">
      <div
        class="flex flex-wrap items-center justify-between px-5 py-1 mx-auto max-w-7xl"
      >
        <router-link to="/">
          <div>
            <img
              src="../assets/logo.svg"
              alt="sungoldwater logo"
              class="h-[70px]"
            />
          </div>
        </router-link>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
