import { render, staticRenderFns } from "./SectionVideo.vue?vue&type=template&id=01b572e0&scoped=true&"
import script from "./SectionVideo.vue?vue&type=script&lang=js&"
export * from "./SectionVideo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b572e0",
  null
  
)

export default component.exports